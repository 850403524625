<template>
  <!--  :footerBtnName="['取消','同步']"-->
      <r-e-dialog title="门锁处理工单" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer
                  :footerBtnName="['取消','提交']"  fullscreen
                  @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
          <div style="">
              <div style="height: 100%;width: 100%;">
                  <el-steps :active="active" style="margin-bottom: 20px;" finish-status="success" process-status="finish">
                      <el-step title="提交处理单">
                          <div slot="description">
                              <div>提交人: {{ submitter }}</div>
                              <div v-if="active > 0">提交时间: {{ submitDate }}</div>
                          </div>
                      </el-step>
                      <el-step title="派单">
                          <div slot="description" v-if="active>=1">
                              <div>派单人: {{ personChargeUserName }}</div>
                              <div v-if="active >= 2">提交时间: {{ personChargeDate }}</div>
                          </div>
                      </el-step>
                      <el-step title="接单">
                          <div slot="description" v-if="active>=2">
                              <div>接单人: {{ confirmUser }}</div>
                              <div v-if="active >= 3">提交时间: {{ confirmDate }}</div>
                          </div>
                      </el-step>
                      <el-step title="维修">
                          <div slot="description" v-if="active >= 3">
                              <div>维修人: {{ handleUser }}</div>
                              <div v-if="active >= 4">提交时间: {{ handleDate }}</div>
                          </div>
                      </el-step>
                      <el-step title="办结">
                          <div slot="description" v-if="active >= 3">
                              <div>提交人: {{ handleUser }}</div>
                              <div v-if="active >= 4">提交时间: {{ handleDate }}</div>
                          </div>
                      </el-step>
                      <el-step title="完成"></el-step>
                  </el-steps>
                  <div style="height: 90%;display: flex;flex-flow: column wrap;">
                    <div class="title" >基本信息</div>
                        <div style="padding: 30px;border: 1px solid #b5adad;border-radius: 5px; ">
                            <div style="display:flex;justify-content: space-between;margin-bottom:10px">
                                <div style="width:300px">小区：金水湾</div>
                                <div style="width:300px">房间号：金水湾</div>
                                <div style="width:300px">租户：张晓瑜</div>
                                <div style="display:flex;width:300px;align-items: center;">
                                    <div style="width: 180px"><span style="color: red;margin-right: 4px">*</span>联系租户电话：</div>
                                    <el-input  v-model="formPublish.leasorPhone"  size="small"></el-input>
                                </div>
                            </div>
                            <div style="display:flex;justify-content: space-between;margin-bottom:10px">
                                <div style="display:flex;align-items: center;width:300px">
                                    <div><span style="color: red;margin-right: 4px">*</span>反馈人员类型：</div>
                                    <el-select style="width: 180px" placeholder="请选择反馈人员类型" size="small">
                                        <el-option label="租户" value=""/>
                                        <el-option label="管家" value=""/>
                                        <el-option label="租赁部" value=""/>
                                    </el-select>
                                </div>
                                <div style="display:flex;align-items: center;width:300px">
                                    <div><span style="color: red;margin-right: 4px">*</span>反馈人员：</div>
                                    <el-select style="width: 180px" placeholder="请选择反馈人员" size="small">
                                        <el-option label="租户" value=""/>
                                        <el-option label="管家" value=""/>
                                        <el-option label="租赁部" value=""/>
                                    </el-select>
                                </div>
                                <div style="display:flex;align-items: center;width:300px">
                                    <div><span style="color: red;margin-right: 4px">*</span>反馈时间：</div>
                                    <el-date-picker type="date" value-format="yyyy-MM-dd" style="width: 180px;"
                                        format="yyyy-MM-dd" placeholder="请选择反馈时间" size="small"/>
                                </div>
                                <div style="width:300px">是否已处理：否</div>
                            </div>
                            <div style="display:flex;justify-content: space-between;margin-bottom:10px">
                                <div style="display:flex;align-items: center;">
                                    <div style="" ><span style="color: red;margin-right: 4px">*</span>门锁问题描述：</div>
                                    <el-input type="textarea" placeholder="请输入门锁问题描述"  style="width: 800px;" :rows="4"/>
                                </div>
                            </div>
                            <div style="display:flex;justify-content: space-between;margin-bottom:10px">
                                <div style="display:flex;align-items: center;">
                                    <div style="width:140px"><span style="color: red;margin-right: 4px;">*</span>门锁问题图片</div>
                                    <div style="width: 100%" >
                                        <upload-picture-card :uuidList="imgList" :limit="3" @on-success="handleSuccess"  :preview-src-list="dialogPoptoPhotos"
                                                            @on-remove="handleRemove"  />
                                    </div>
                                </div>
                            </div>
                            <div style="display:flex;justify-content: space-between;margin-bottom:10px">
                                <div style="display:flex;align-items: center;">
                                    <div style="width:110px">门锁问题视频</div>
                                    <div>
                                        <upload-video  accept=".mp4" :disabledtype="disabledtype" :deletetype="deletetype" @delete-video="deleteUploadedVideo" @on-success="handleUploadSuccess" :uuid="checkOurData.videoUrl"/>
                                    </div>
                                </div>
                            </div>
                            <div style="display:flex;justify-content: space-between;margin-bottom:10px">
                                <div style="display:flex;align-items: center;">
                                    <div style="width:110px">备注：</div>
                                    <el-input type="textarea" placeholder="请输入门锁问题描述"  style="width: 800px;" :rows="4"/>
                                </div>
                            </div>
                        </div>

                  </div>
              </div>
          </div>

          <!--租金审批-->
          <!-- <div v-if="typesuijisu === 2">
            <div class="box_tap">
              <h3>房源信息</h3>
            </div>
            <div class="box_bnt_zujin">
              <div class="item">
                房间：{{ formPublish.apartmentName }}
              </div>
              <div class="item">
                房型：{{ formPublish.hourseLayout }}
              </div>
              <div class="item">
                建筑面积(㎡)：{{ formPublish.outsideArea }}㎡
              </div>

              <div class="item" style="display: flex">
                <div v-if="formPublish.myNmae === '单价'" style="width: 302px">
                  单价(元/㎡)：{{ (formPublish.monthlyRent / 100).toFixed(2) }}
                </div>
                <div v-if="formPublish.myNmae === '单价'">租金(元/月)：{{ formPublish.MYmonthlyRent }}</div>
                <div v-if="formPublish.myNmae !== '单价'">租金(元/月)：{{ formPublish.monthlyRent / 100 }}</div>

              </div>
              <div class="item" style="display: flex;align-items: center">
                地址：{{ formPublish.apartmentAddress }}
              </div>
              <div class="item" style="display: flex;align-items: center">
                <div style="width: 100px">申请修改租金：</div>
                <el-input v-if="formPublish.workOrderStatus === '待发起'" style="width: 100px"
                          v-model="formPublish.rentUpdatePrice" placeholder="请输入内容"></el-input>
                <span v-if="formPublish.workOrderStatus === '待发起'">元</span>
                <div v-if="formPublish.workOrderStatus !== '待发起'"
                     style="border-bottom: solid 1px #BBBBBB;width: 200px;text-align: center">
                  {{ formPublish.rentUpdatePrice }}
                </div>
                <span v-if="formPublish.workOrderStatus !== '待发起'">元</span>
              </div>

              <div class="item" style="display: flex;align-items: center" v-if="formPublish.myNmae === '单价'">
                <div style="width: 250px">修改后租金（单价*面积=月租金）：</div>
              
                <div style="border-bottom: solid 1px #BBBBBB;min-width: 140px;text-align: center">{{ money }}</div>
                元
              </div>

              <div v-else class="item">
              </div>
              <div class="item">

              </div>
              <div class="item">

              </div>
              <div class="item">

              </div>

              <div class="item">
              </div>

              <div class="item">
              </div>

              <div class="item" style="display: flex;align-items: center">
                <div style="width: 100px">申请原因：</div>
                <el-input v-if="formPublish.workOrderStatus === '待发起'" type="textarea"
                          style="width: 350px;margin-top: 20px" v-model="formPublish.rentUpdateReason"
                          placeholder="请输入内容"></el-input>
                <div v-if="formPublish.workOrderStatus !== '待发起'"
                     style="border-bottom: solid 1px #BBBBBB;width: 400px;">{{ formPublish.rentUpdateReason }}
                </div>
              </div>

              <div class="item">
              </div>
              <div class="item">
              </div>
            </div>
          </div> -->
          <!-- <div v-if="typesuijisu === 3">
            <div class="box_tap">
              <h3>承租信息</h3>
            </div>
            <div class="box_bnt">
              <div class="item">
                承租人：{{ formPublish.leasorName }}
              </div>
              <div class="item">
                手机号码：{{ formPublish.leasorPhone }}
              </div>
              <div class="item">
                证件号码：{{ formPublish.idCard }}
              </div>
              <div class="item">
                承租人类型：{{ formPublish.leasorType === '个人' ? '个人' : '企业' }}
              </div>
              <div class="item">
                房间：{{ formPublish.apartmentName }}
              </div>
              <div class="item">
                合同周期：{{ formPublish.contractPeriod }}
              </div>
              <div class="item">
              </div>
              <div class="item">
              </div>
            </div>
          </div>
          <div v-if="typesuijisu === 3">
            <div class="box_tap">
              <h3>密码信息</h3>
            </div>
            <div class="box_bnt" style="height: 130px">
              <div class="item">
                申请天数：{{ formPublish.days }}天
              </div>
              <div class="item">
                接收号码：{{ formPublish.phone }}
              </div>
              <div class="item">
                历史累计时长：{{ formPublish.totalHistoryDurationStr}}
              </div>
              <div class="item">
              </div>

              <div class="item" style="display: flex;align-items: center">
                <div style="width: 100px">申请原因：</div>
                <el-input v-if="formPublish.workOrderStatus === '待发起'" type="textarea"
                          style="width: 350px;margin-top: 20px;margin-left: -30px" v-model="formPublish.passApplyReason"
                          placeholder="请输入内容"></el-input>
                <div v-if="formPublish.workOrderStatus !== '待发起'"
                     style="border-bottom: solid 1px #BBBBBB;width: 400px;">{{ formPublish.passApplyReason  }}
                </div>
              </div>
              <div class="item">
              </div>

              <div class="item">
              </div>
              <div class="item">
              </div>
            </div>
          </div> -->
          <!-- <div  style="margin-top: 40px">
            <el-badge :value="formPublish.handleNum" :max="99" class="item">
              <el-button @click="chuLijilu" size="small" type="primary">处理记录</el-button>
            </el-badge>
            <el-button @click="chuLijilu" size="small" type="primary">处理记录</el-button>
          </div> -->

          <r-e-dialog :title="billTitle" :visible.sync="billSwitch" show-footer top="5vh" width="1000px"
                      :showFooter="false" @click-cancel="billListClose" v-dialog-drag>
              <r-e-table ref="tableRef" :columns="houseBillColumns" :data="billList" :height="500" :showPagination="false"
                         show-summary :summary-method="getSummaries">
                  <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
              </r-e-table>
          </r-e-dialog>
          <r-e-dialog title="驳回" :visible.sync="rejectShow" show-footer top="5vh" showFooter width="500px"
                      @click-submit="rejectSubmit" @click-cancel="rejectCancel" @close="rejectCancel" v-dialog-drag>
              <el-form ref="rejectForm" :model="rejectForm" label-width="80px" size="small" :rules="rejectRules">
                  <el-form-item label="驳回原因" prop="reason">
                      <el-input type="textarea" v-model="rejectForm.reason" placeholder="请输入驳回原因" :rows="4"/>
                  </el-form-item>
              </el-form>
          </r-e-dialog>
  
          <r-e-dialog title="处理记录" :visible.sync="handleRecordSwitch" show-footer top="5vh" width="1000px"
                      :showFooter="false" @click-cancel="handleRecordSwitch=false" v-dialog-drag>
              <r-e-table ref="tableHandleRecordRef" :columns="handleRecordColumns" :data="checkOutDisposeLog"
                         :height="500" border
                         :showPagination="false"/>
          </r-e-dialog>
      </r-e-dialog>
  </template>
  
  <script>
  import {MessageInfo, MessageSuccess} from "@custom/message";
  import {timeFormat, numberFormat} from "@custom/index";
  import {
      getWordInfo, wordDispose, wordAudit, checkoutAuditRejectionApi,newobjgongdanApi
  } from "@/api/work-order-management";
  import {contractCheckoutExit} from "@/api/contract";
  import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
  import {houseBillColumns} from "@/views/rental-management/house-resource/data";
  import {getPaybillUuidsList,synchronizationapi} from "@/api/bill";
  import uploadVideo from "@/components/Upload/video-upload-perfect.vue";
  import {createFullImageUrl} from "@/components/Upload";
  // video-upload-perfect
  export default {
      name: "dialog-check-out-work-order-handle",
      data() {
  
          return {
            formPublish:{
              leasorPhone:null, // 租户联系电话
            },
            showViewer: false,
            dialogPoptoPhotos:[],
              disabledtype:false,
              deletetype:false,
              shanchu: true,
              dialogVisible: false,
              title: '',
              loading: false,
              paymentTypeList: [],
              imgList: [],
              checkOurDateShow: false,
              endDate: null,
              uuid: null,
              contractUuid:null,
              checkOurData: {
                  videoUrl:'',
                  contractUuid: null,
                  aprtmentName: '0',
                  leasor: '0',
                  checkOutDate: null,
                  depositAmount: null,
                  depositDate: null,
                  rentalDate: null,
                  rentalAmount: null,
                  serviceDate: null,
                  serviceAmount: null,
                  rentalBalance: null,
                  expenseBalance: null,
                  depoTemporaryBalance:null,
                  noPaidRentalDate: null,
                  noPaidRentalAmount: null,
                  noPaidServiceDate: null,
                  noPaidServiceAmount: null,
                  noPaidWaterDate: null,
                  noPaidWaterAmount: null,
                  noPaidElectDate: null,
                  noPaidElectAmount: null,
                  clean: '0.00',
                  damage: '0.00',
                  other: '0.00',
                  dedit: '0.00',
                  payAmount: 0,
                  refundAmopunt: 0,
                  totalAmount: 0,
                  reasonCode: 1,
                  reasonName: '到期',
                  remitter: null,
                  bank: null,
                  cardNum: null,
                  comment: null,
                  paymentMethod: null,
                  gzfTicket: {}
              },
              rentalAmountShow: false,
              serviceAmountShow: false,
              rentalBalanceShow: false,
              expenseBalanceShow: false,
              noPaidRentalAmountShow: false,
              noPaidServiceAmountShow: false,
              noPaidWaterAmountShow: false,
              noPaidElectAmountShow: false,
              cleanShow: false,
              damageShow: false,
              otherShow: false,
              deditShow: false,
              state: null,
              loadingOptions: {
                  lock: true,
                  text: "Loading...",
                  spinner: "el-icon-loading",
                  background: "rgba(0, 0, 0, 0.7)"
              },
              billTitle: "",
              billSwitch: false,
              houseBillColumns: houseBillColumns(this),
              billList: [],
              billUuids: null,
              active: 4,
              submitter: null,    //申请人
              submitDate: null,   //申请时间
              personChargeUserName: null, //片管员
              personChargeDate: null, //片管员操作时间
              confirmUser: null,  //租赁部
              confirmDate: null,  //租赁部操作时间
              handleUser: null,   //财务部
              handleDate: null,   //财务部操作时间
              rejectShow: false,
              rejectForm: {
                  reason: null,
              },
              rejectRules: {
                  reason: [{required: true, message: '请输入驳回原因', trigger: 'blur'}]
              },
              checkOutDisposeLog: [],
              handleRecordSwitch: false,
              handleRecordColumns: [
                  {prop: "name", label: "处理人", width: 100},
                  {prop: "operatingState", label: "操作", width: 100},
                  {prop: "remark", label: "驳回原因",},
                  {prop: "controlsDate", label: "时间", width: 150},
              ]
          };
      },
      components: {
        uploadVideo,
          uploadPictureCard
      },
      props: {},
      methods: {
        createFullImageUrl,
        // 上传视频事件
        handleUploadSuccess({info}) {
          // 上传视频
          this.videoUrl = info.uuid;
          this.checkOurData.videoUrl = info.uuid;
        },
         //2024/04/17 张晓瑜新增删除已上传的视频
         deleteUploadedVideo() {
          this.checkOurData.videoUrl = ''; // 清空视频 UUID
          console.log('this.formPublish.videoUrl0',this.checkOurData.videoUrl);
          // this.isUploaded = false; // 标记未上传视频
          },
          async openDialog(data) {
          //   console.log('data,',data)
              // this.title = '处理';
              // const {uuid, state, submitter, createDate,contractUuid,applyTime} = data;
              // this.active = state;
              // this.uuid = uuid;
              // this.contractUuid = contractUuid
              // this.state = state;
              // this.submitter = submitter;
              // this.submitDate = timeFormat(new Date(createDate), "yyyy-MM-dd HH:mm:ss");
              // //张晓瑜修改退房时间
              // this.checkOurData.checkOutDate = timeFormat(new Date(applyTime));
              // await this.getWordInfo();
              this.dialogVisible = true;
  
              this.$nextTick(() => {
                  // if (state !== 0) {
                  //     const bh = this.$refs['bh'].$el.parentNode.childNodes[2];
                  //     const qx = this.$refs['bh'].$el.parentNode.childNodes[0];
                  //     this.$refs['bh'].$el.parentNode.insertBefore(bh, qx);
                  // }
              });
          },
          async getWordInfo() {
              let that = this;
              let uuid = this.uuid;
              // alert(uuid)
              const active = this.active;
              // alert(that.checkOurData.checkOutDate)
              console.log(that.checkOurData.checkOutDate)
              const loadingOptions = that.loadingOptions;
              const loading = that.$loading({...loadingOptions});
              let {info} = await getWordInfo(uuid).catch(err => loading.close());
              that.deletetype = false
              that.disabledtype = true //默认不可以传视频
              // 2024-3-11-陈守亮修改数据--判断是第一步
            if(active === 0){
              let res= await newobjgongdanApi({uuid:that.contractUuid,checkOutDate:that.checkOurData.checkOutDate}).catch(err => loading.close());
              that.deletetype = true
              that.disabledtype = false  //如果是第一步基本可以传视频
              console.log(res)
              info.depositDate = res.info.depositDate
              info.depositAmount = res.info.depositAmount
              info.rentalDate = res.info.rentalDate
              info.rentalAmount = res.info.rentalAmount
              info.serviceDate = res.info.serviceDate
              info.serviceAmount = res.info.serviceAmount
              info.noPaidRentalDate = res.info.noPaidRentalDate
              info.noPaidRentalAmount = res.info.noPaidRentalAmount
              info.noPaidServiceDate = res.info.noPaidServiceDate
              info.noPaidServiceAmount = res.info.noPaidServiceAmount
              info.noPaidWaterDate = res.info.noPaidWaterDate
              info.noPaidWaterAmount = res.info.noPaidWaterAmount
              info.noPaidElectDate = res.info.noPaidElectDate
              info.noPaidElectAmount = res.info.noPaidElectAmount
            }
              // console.log(res.info.depositDate,info.depositDate)
              let {videoUrl,
                  depositAmount, depositDate, rentalDate, rentalAmount, serviceDate, serviceAmount, rentalBalance,
                  expenseBalance, noPaidRentalDate, noPaidRentalAmount, noPaidServiceDate, noPaidServiceAmount,
                  noPaidWaterDate, noPaidWaterAmount, noPaidElectDate, noPaidElectAmount, aprtmentName, leasor,
                  workPictures, comment, contractUuid, bank, cardNum, remitter, paymentMethod, reasonCode, reasonName,
                  clean, damage, other, dedit, totalAmount, refundAmopunt, payAmount, checkOutDate,
                  noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
                  noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
                  serviceUuids, depositCount, depositUuids, gzfTicket, submitUserName, submitDate = null,
                  disposeUserName = null, disposeDate = null, remittanceUserName = null, remittanceDate = null,
                  personChargeUserName = null, personChargeDate = null, checkOutDisposeLog = [],
                 depoTemporaryBalance
              } = info;
              gzfTicket = gzfTicket || {}
  
              if (checkOutDisposeLog.length > 0) {
                  checkOutDisposeLog = checkOutDisposeLog.map(item => {
                      let {controlsDate} = item;
                      controlsDate = controlsDate ? timeFormat(new Date(controlsDate), "yyyy-MM-dd HH:mm:ss") : null;
                      return {...item, controlsDate}
                  });
              }
              // console.log("gzfTicket", gzfTicket)
              // this.submitter = submitUserName;
              // this.submitDate = submitDate ? timeFormat(new Date(submitDate), "yyyy-MM-dd HH:mm:ss") : '';
  
              that.checkOutDisposeLog = checkOutDisposeLog;
              that.personChargeUserName = personChargeUserName ? personChargeUserName : '';
              that.personChargeDate = personChargeDate ? timeFormat(new Date(personChargeDate), "yyyy-MM-dd HH:mm:ss") : '';
              that.confirmUser = disposeUserName ? disposeUserName : '';
              that.confirmDate = disposeDate ? timeFormat(new Date(disposeDate), "yyyy-MM-dd HH:mm:ss") : '';
              that.handleUser = remittanceUserName ? remittanceUserName : '';
              that.handleDate = remittanceDate ? timeFormat(new Date(remittanceDate), "yyyy-MM-dd HH:mm:ss") : '';
  
              if (active === 1) {
                  that.personChargeUserName = localStorage.getItem("userName");
              }
              if (active === 2) {
                  that.confirmUser = localStorage.getItem("userName");
              }
              if (active === 3) {
                  that.handleUser = localStorage.getItem("userName");
              }
  
              checkOutDate = timeFormat(new Date(checkOutDate));
              depositAmount = parseFloat((depositAmount / 100)).toFixed(2);
              rentalAmount = parseFloat(rentalAmount / 100).toFixed(2);
              serviceAmount = parseFloat(serviceAmount / 100).toFixed(2);
              rentalBalance = parseFloat(rentalBalance / 100).toFixed(2);
              expenseBalance = parseFloat(expenseBalance / 100).toFixed(2);
              depoTemporaryBalance = parseFloat(depoTemporaryBalance / 100).toFixed(2);
              noPaidRentalAmount = parseFloat(noPaidRentalAmount / 100).toFixed(2);
              noPaidServiceAmount = parseFloat(noPaidServiceAmount / 100).toFixed(2);
              noPaidWaterAmount = parseFloat(noPaidWaterAmount / 100).toFixed(2);
              noPaidElectAmount = parseFloat(noPaidElectAmount / 100).toFixed(2);
              clean = parseFloat(clean / 100).toFixed(2);
              damage = parseFloat(damage / 100).toFixed(2);
              other = parseFloat(other / 100).toFixed(2);
              dedit = parseFloat(dedit / 100).toFixed(2);
              totalAmount = parseFloat(totalAmount / 100).toFixed(2);
              refundAmopunt = parseFloat(refundAmopunt / 100).toFixed(2);
              payAmount = parseFloat(payAmount / 100).toFixed(2);
              let oldCheckOurData = this.checkOurData;
              this.checkOurData = {
                  ...oldCheckOurData, depositAmount, depositDate, rentalDate, rentalAmount, serviceDate,
                  serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
                  noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
                  noPaidElectAmount, aprtmentName, leasor, comment, contractUuid, bank, cardNum, remitter, paymentMethod,
                  reasonCode, reasonName, clean, damage, other, dedit, totalAmount, payAmount, refundAmopunt,
                  noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
                  noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
                  serviceUuids, depositCount, depositUuids, checkOutDate, gzfTicket,videoUrl,depoTemporaryBalance
              }
            // this.imgList = workPictures ? workPictures.split(";").filter(item => item !== "") : [];
            // 优化照片显示分割-陈守亮-2024-4-3=28
            if (workPictures.indexOf(",") !== -1) {
              console.log("字符串使用逗号分割");
              this.imgList = this.splitAndFilter(workPictures, ',');
            } else if (workPictures.indexOf(";") !== -1) {
              console.log("字符串使用分号分割");
              this.imgList = this.splitAndFilter(workPictures, ';');
            } else {
              this.imgList.push(workPictures)
              console.log("字符串没有逗号或分号");
            }
            // 陈守亮新增9-18-旋转图片
            this.dialogPoptoPhotos = this.imgList.map(item => {
              return this.createFullImageUrl(item)
            })
              this.calculationRefundAmopunt();
              this.calculationpPayAmount();
              loading.close();
          },
         // 处理字符串函数，照片分割字符串
         splitAndFilter(workPictures, separator = ',') {
          if (typeof workPictures !== 'string') {
            console.error('workPictures should be a string');
            return [];
          }
  
          return workPictures.split(separator).filter(item => item);
        },
  
        setCheckOurDate() {
              this.checkOurDateShow = true;
              this.$nextTick(() => this.$refs["checkOurDate"].focus());
          },
          checkOurDateBlur() {
              this.checkOurDateShow = false;
              this.getCheckoutInfo();
          },
          amountBlur(name, ref) {
              this[name] = false;
              this.checkOurData[ref] = (parseFloat(this.checkOurData[ref])).toFixed(2);
              this.calculationRefundAmopunt();
              this.calculationpPayAmount();
          },
          amountClick(name, ref) {
              if (this.state !== 0) {
                  return;
              }
  
              this[name] = true;
              this.$nextTick(() => this.$refs[ref].focus());
          },
          setReason(code, name) {
              if (this.state !== 0) {
                  return;
              }
              this.checkOurData.reasonCode = code;
              this.checkOurData.reasonName = name;
          },
          calculationRefundAmopunt() {
              let {depositAmount, rentalAmount, serviceAmount, rentalBalance, expenseBalance ,depoTemporaryBalance} = this.checkOurData;
              depositAmount = parseFloat(depositAmount);
              rentalAmount = parseFloat(rentalAmount);
              serviceAmount = parseFloat(serviceAmount);
              rentalBalance = parseFloat(rentalBalance);
              expenseBalance = parseFloat(expenseBalance);
              depoTemporaryBalance = parseFloat(depoTemporaryBalance);
              this.checkOurData.refundAmopunt = (depositAmount + rentalAmount + serviceAmount + rentalBalance + expenseBalance + depoTemporaryBalance).toFixed(2);
              this.calculationpTotalAmount();
          },
          calculationpPayAmount() {
              let {
                  noPaidRentalAmount, noPaidServiceAmount, noPaidWaterAmount, noPaidElectAmount, clean, damage, other,
                  dedit
              } = this.checkOurData;
              noPaidRentalAmount = parseFloat(noPaidRentalAmount);
              noPaidServiceAmount = parseFloat(noPaidServiceAmount);
              noPaidWaterAmount = parseFloat(noPaidWaterAmount);
              noPaidElectAmount = parseFloat(noPaidElectAmount);
              clean = parseFloat(clean);
              damage = parseFloat(damage);
              other = parseFloat(other);
              dedit = parseFloat(dedit);
  
              this.checkOurData.payAmount = (noPaidRentalAmount + noPaidServiceAmount + noPaidWaterAmount +
                  noPaidElectAmount + clean + damage + other + dedit).toFixed(2);
              this.calculationpTotalAmount();
          },
          calculationpTotalAmount() {
              let refundAmopunt = this.checkOurData.refundAmopunt;
              let payAmount = this.checkOurData.payAmount;
              let {amount = 0} = this.checkOurData.gzfTicket;
              let depositAmount = this.checkOurData.depositAmount;
              amount = parseFloat((amount / 100).toFixed(2));
              this.checkOurData.totalAmount = (parseFloat(payAmount) - parseFloat(refundAmopunt) - amount).toFixed(2);
          },
          clickSubmit() {
              let that = this;
              let uuid = that.uuid;
              let state = that.state;
  
              if (that.state !== 0) {
                  that.checkoutAuditRejection({state: 0, uuid, remark: null, checkOutState: state});
              }
  
              if (that.state === 0) {
                  let {
                      aprtmentName, leasor, checkOutDate, depositAmount, depositDate, rentalDate, rentalAmount,
                      serviceDate, serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
                      noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
                      noPaidElectAmount, clean, damage, other, dedit, payAmount, refundAmopunt, totalAmount, reasonCode,
                      reasonName, remitter, bank, cardNum, comment, paymentMethod, contractUuid, noPaidRentalCount,
                      noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount, noPaidWaterUuids,
                      noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount, serviceUuids,
                      depositCount, depositUuids,videoUrl,depoTemporaryBalance
                  } = that.checkOurData;
                  depositAmount = parseInt((parseFloat(depositAmount) * 100).toFixed(0));
                  rentalAmount = parseInt((parseFloat(rentalAmount) * 100).toFixed(0));
                  serviceAmount = parseInt((parseFloat(serviceAmount) * 100).toFixed(0));
                  rentalBalance = parseInt((parseFloat(rentalBalance) * 100).toFixed(0));
                  expenseBalance = parseInt((parseFloat(expenseBalance) * 100).toFixed(0));
                  depoTemporaryBalance = parseInt((parseFloat(depoTemporaryBalance) * 100).toFixed(0));
                  noPaidRentalAmount = parseInt((parseFloat(noPaidRentalAmount) * 100).toFixed(0));
                  noPaidServiceAmount = parseInt((parseFloat(noPaidServiceAmount) * 100).toFixed(0));
                  noPaidWaterAmount = parseInt((parseFloat(noPaidWaterAmount) * 100).toFixed(0));
                  noPaidElectAmount = parseInt((parseFloat(noPaidElectAmount) * 100).toFixed(0));
                  clean = parseInt((parseFloat(clean) * 100).toFixed(0));
                  damage = parseInt((parseFloat(damage) * 100).toFixed(0));
                  other = parseInt((parseFloat(other) * 100).toFixed(0));
                  dedit = parseInt((parseFloat(dedit) * 100).toFixed(0));
                  payAmount = parseInt((parseFloat(payAmount) * 100).toFixed(0));
                  refundAmopunt = parseInt((parseFloat(refundAmopunt) * 100).toFixed(0));
                  totalAmount = parseInt((parseFloat(totalAmount) * 100).toFixed(0));
                  // 退租原因校验
                  if (reasonCode === null || reasonCode === '') {
                      MessageInfo('请选择退租原因');
                      return;
                  }
                  let workPictures = that.imgList.join(";");
                  if (totalAmount < 0) {
                      if (remitter === null || remitter === '') {
                          MessageInfo('请填写汇款人');
                          return;
                      }
                      if (cardNum === null || cardNum === '') {
                          MessageInfo('请填写银行卡号');
                          return;
                      }
                      if (bank === null || bank === '') {
                          MessageInfo('请填写开户行');
                          return;
                      }
                  }
                  if (totalAmount > 0) {
                      if (paymentMethod === null || paymentMethod === '') {
                          MessageInfo('请选择付款方式');
                          return;
                      }
                  }
                  let data = {
                      aprtmentName, leasor, checkOutDate, depositAmount, depositDate, rentalDate, rentalAmount,
                      serviceDate, serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
                      noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
                      noPaidElectAmount, clean, damage, other, dedit, payAmount, refundAmopunt, totalAmount, reasonCode,
                      reasonName, remitter, bank, cardNum, comment, workPictures, uuid, paymentMethod, contractUuid,
                      noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
                      noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
                      serviceUuids, depositCount, depositUuids, workOrderStatus: 1,videoUrl,depoTemporaryBalance
                  };
  
                  const loadingOptions = that.loadingOptions;
                  const loading = that.$loading({...loadingOptions});
                  //调用接口提交退房
                  contractCheckoutExit(data).then(res => {
                      loading.close();
                      MessageSuccess('提交成功');
                      //调用取消方法，关闭弹窗
                      that.clickCancel();
                  }).catch(() => loading.close());
              }
  
              /*if (state === 2) {
                  this.$confirm('此操作将处理, 是否继续?', '注意', {
                      type: 'warning'
                  }).then(() => {
                      const loadingOptions = that.loadingOptions;
                      const loading = that.$loading({...loadingOptions});
                      wordDispose(data).then(res => {
                          MessageSuccess('处理成功');
                          that.clickCancel();
                          loading.close();
                      }).catch(err => loading.close());
                  }).catch(() => {
                  });
              } else if (state === 3) {
                  this.$confirm('此操作将汇款, 是否继续?', '注意', {
                      type: 'warning'
                  }).then(() => {
                      const loadingOptions = that.loadingOptions;
                      const loading = that.$loading({...loadingOptions});
                      wordAudit(data).then(res => {
                          MessageSuccess('汇款成功');
                          that.clickCancel();
                          loading.close();
                      }).catch(err => loading.close());
                  }).catch(() => {
                  });
              }*/
          },
          clickCancel() {
              // return
            // alert('取消')
              this.$emit('handleSearch');
              this.dialogVisible = false;
              this.checkOurData = {
                videoUrl:'',
                  contractUuid: null, aprtmentName: '0', leasor: '0', checkOutDate: null, depositAmount: null,
                  depositDate: null, rentalDate: null, rentalAmount: null, serviceDate: null, serviceAmount: null,
                  rentalBalance: null, expenseBalance: null, noPaidRentalDate: null, noPaidRentalAmount: null,
                  noPaidServiceDate: null, noPaidServiceAmount: null, noPaidWaterDate: null, noPaidWaterAmount: null,
                  noPaidElectDate: null, noPaidElectAmount: null, clean: '0.00', damage: '0.00', other: '0.00',
                  dedit: '0.00', payAmount: 0, refundAmopunt: 0, totalAmount: 0, reasonCode: 1, reasonName: '到期',
                  remitter: null, bank: null, cardNum: null, comment: null, paymentMethod: null, gzfTicket: {}
              };
              this.imgList = [];
              this.uuid = null;
          },
  
          handleSuccess({fileList}) {
              this.imgList = fileList.map(item => {
                  let {name, response} = item;
                  let resOk = response && typeof (response) !== undefined;
                  if (resOk) {
                      let {returnObject: {info: {uuid}}} = response;
                      return uuid;
                  } else return name;
              });
          },
  
          handleRemove({fileList}) {
              this.imgList = fileList.map(item => {
                  let {name, response} = item;
                  let resOk = response && typeof (response) !== undefined;
                  if (resOk) {
                      let {returnObject: {info: {uuid}}} = response;
                      return uuid;
                  } else return name;
              });
          },
          async goBill(type, data) {
              if (data) {
                  let that = this;
                  that.billTitle = type;
                  that.billUuids = data;
                  const loadingOptions = that.loadingOptions;
                  const loading = that.$loading({...loadingOptions});
                  getPaybillUuidsList({uuids: data}).then(res => {
                      const {list} = res;
                      that.billList = list;
                      that.billSwitch = true;
                  }).finally(() => loading.close());
              } else MessageInfo('无' + type + '账单');
          },
          update(data) {
              this.$refs["dialogAddBill"].openDialog(data);
          },
          billClickCancel() {
              const {billTitle, billUuids} = this;
              this.goBill(billTitle, billUuids);
              this.getCheckoutInfo();
          },
          billListClose() {
              this.billTitle = null;
              this.billUuids = null;
              this.billList = null;
              this.billSwitch = false;
          },
          getSummaries(param) {
              const {columns, data} = param;
              const sums = [];
              columns.forEach((column, index) => {
                  if (index === 0) {
                      sums[index] = '合计';
                      return;
                  }
                  const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                  if (!values.every(value => isNaN(value))) {
                      sums[index] = values.reduce((prev, curr) => {
                          const value = Number(curr);
                          if (!isNaN(value)) return prev + curr;
                          else return prev;
                      }, 0);
                      sums[index] = numberFormat(sums[index] / 100);
                  } else sums[index] = '';
  
              });
              return sums;
          },
  
          numberFormat(number) {
              return numberFormat(Number(number) / 100);
          },
  
          /*deletewei() {
              let that = this;
              this.$confirm('此操作将删除违约金, 是否继续?', '注意', {
                  type: 'warning',
              }).then(() => {
                  const loadingOptions = that.loadingOptions;
                  const loading = that.$loading({...loadingOptions});
                  loading.close();
                  var odiv = document.getElementById("div1");
                  if (odiv.style.display == "block") {
                      odiv.style.display = "none";
                  }
                  this.dedit = 0.00;
                  this.checkOurData.dedit = '0.00'
                  this.calculationpPayAmount();
                  this.calculationpTotalAmount();
              }).catch(() => {
              });
          }*/
  
          //驳回按钮点击事件
          rejectFun() {
              this.rejectShow = true;
          },
          //同步点击事件
        async synchronization(){
            // alert('同步')
          await synchronizationapi({uuid:this.uuid}).then(res => {
            console.log(res)
            this.openDialog(res.info)
          })
          MessageSuccess('同步成功');
        },
          rejectCancel() {
              this.rejectShow = false;
              this.rejectForm.reason = null;
          },
  
          rejectSubmit() {
              const that = this;
              this.$refs['rejectForm'].validate((valid) => {
                  if (valid) {
                      const remark = that.rejectForm.reason;
                      const uuid = this.uuid;
                      const checkOutState = that.state;
                      const data = {state: 1, remark, uuid, checkOutState}
                      that.checkoutAuditRejection(data);
                  }
              });
          },
  
          checkoutAuditRejection(data) {
              const that = this;
              const loadingOptions = that.loadingOptions;
              const loading = that.$loading({...loadingOptions});
              checkoutAuditRejectionApi(data).then(res => {
                  MessageSuccess((data.state === 1 ? '驳回' : '处理') + '成功');
                  that.rejectCancel();
                  that.clickCancel();
              }).finally(() => loading.close());
          },
  
          saveWorkOrder() {
              let that = this;
              let uuid = that.uuid;
              let {
                  aprtmentName, leasor, checkOutDate, depositAmount, depositDate, rentalDate, rentalAmount,
                  serviceDate, serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
                  noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
                  noPaidElectAmount, clean, damage, other, dedit, payAmount, refundAmopunt, totalAmount, reasonCode,
                  reasonName, remitter, bank, cardNum, comment, paymentMethod, contractUuid, noPaidRentalCount,
                  noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount, noPaidWaterUuids,
                  noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount, serviceUuids,
                  depositCount, depositUuids,videoUrl,depoTemporaryBalance
              } = that.checkOurData;
              depositAmount = parseInt((parseFloat(depositAmount) * 100).toFixed(0));
              rentalAmount = parseInt((parseFloat(rentalAmount) * 100).toFixed(0));
              serviceAmount = parseInt((parseFloat(serviceAmount) * 100).toFixed(0));
              rentalBalance = parseInt((parseFloat(rentalBalance) * 100).toFixed(0));
              expenseBalance = parseInt((parseFloat(expenseBalance) * 100).toFixed(0));
              noPaidRentalAmount = parseInt((parseFloat(noPaidRentalAmount) * 100).toFixed(0));
              noPaidServiceAmount = parseInt((parseFloat(noPaidServiceAmount) * 100).toFixed(0));
              depoTemporaryBalance = parseInt((parseFloat(depoTemporaryBalance) * 100).toFixed(0));
              noPaidWaterAmount = parseInt((parseFloat(noPaidWaterAmount) * 100).toFixed(0));
              noPaidElectAmount = parseInt((parseFloat(noPaidElectAmount) * 100).toFixed(0));
              clean = parseInt((parseFloat(clean) * 100).toFixed(0));
              damage = parseInt((parseFloat(damage) * 100).toFixed(0));
              other = parseInt((parseFloat(other) * 100).toFixed(0));
              dedit = parseInt((parseFloat(dedit) * 100).toFixed(0));
              payAmount = parseInt((parseFloat(payAmount) * 100).toFixed(0));
              refundAmopunt = parseInt((parseFloat(refundAmopunt) * 100).toFixed(0));
              totalAmount = parseInt((parseFloat(totalAmount) * 100).toFixed(0));
  
              let workPictures = that.imgList.join(";");
  
              let data = {
                  aprtmentName, leasor, checkOutDate, depositAmount, depositDate, rentalDate, rentalAmount,
                  serviceDate, serviceAmount, rentalBalance, expenseBalance, noPaidRentalDate, noPaidRentalAmount,
                  noPaidServiceDate, noPaidServiceAmount, noPaidWaterDate, noPaidWaterAmount, noPaidElectDate,
                  noPaidElectAmount, clean, damage, other, dedit, payAmount, refundAmopunt, totalAmount, reasonCode,
                  reasonName, remitter, bank, cardNum, comment, workPictures, uuid, paymentMethod, contractUuid,
                  noPaidRentalCount, noPaidRentalUuids, noPaidServiceCount, noPaidServiceUuids, noPaidWaterCount,
                  noPaidWaterUuids, noPaidElectCount, noPaidElectUuids, rentalCount, rentalUuids, serviceCount,
                  serviceUuids, depositCount, depositUuids, workOrderStatus: 0,videoUrl,depoTemporaryBalance
              };
  
              const loadingOptions = that.loadingOptions;
              const loading = that.$loading({...loadingOptions});
              //调用接口提交退房
              contractCheckoutExit(data).then(res => {
                  loading.close();
                  MessageSuccess('保存成功');
                  //调用取消方法，关闭弹窗
                  that.clickCancel();
              }).catch(() => loading.close());
          }
      },
      async created() {
          this.paymentTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500100");
          this.paymentTypeList = this.paymentTypeList.filter(item => item.code !== 500101 && item.code !== 500102 && item.code !== 500106);
      },
      watch: {
          'formPublish.isForce'(value) {
              if (!value) {
                  this.formPublish.depoBalanceRefund = 0;
              }
          }
      }
  }
  </script>
  
  <style scoped>
  
  /* .el-input {
  .el-input__inner {
    border: none;
    border-bottom: #8c939d 1px solid;
    border-radius: 0px;
    margin-top: -150px;
  }

} */
   /deep/ .avatar-uploader-icon{
      width: 150px;
      height: 128px;
      line-height: 128px;
   }
   /deep/ .avatar{
      width: 150px;
      height: 128px;
   }
  .row {
      margin-bottom: 10px;
      padding: 10px 20px;
      border: 1px solid #D7D7D7;
  }
  .display-one{
      display:flex;
      justify-content: space-between;
  }
  
  .width300 {
      width: 300px;
  }
  
  .width100 {
      width: 100px;
  }
  
  .boder-right {
      border-right: 1px solid #D7D7D7;
  }
  
  .title {
      border-left: 5px #F5A623 solid; 
      padding-left: 5px;
      margin: 15px 0;
      font-weight: 600;
      font-size:18px
  }
  
  .bg-E6E6E6 {
      background-color: #E6E6E6;
  }
  
  .bg-E2F5FF {
      background-color: #E2F5FF;
  }
  
  .bg-F8F8F8 {
      background-color: #F8F8F8;
  }
  
  .width33b {
      width: 33%;
  }
  
  .padding_0_30 {
      padding: 0 30px
  }
  
  .padding_10_30 {
      padding: 10px 30px;
  }
  
  .fw600 {
      font-weight: 600;
  }
  .list{
    display:flex
  }
  </style>
  